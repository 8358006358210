<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron datossistema"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="iddato_sistema"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <dato-sistema-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :has-perm-borrar="hasDeletePerm($route.meta.permission.idobjeto)"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <b10-fab-button
      v-if="hasInsertPerm($route.meta.permission.idobjeto)"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './DatoSistemaListData'
import DatoSistemaListItem from './components/DatoSistemaListItem'
import { get } from 'vuex-pathify'


const pageStoreName = 'pagesDatoSistemaList'

export default {
  components: {
    DatoSistemaListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    addRoute: {
      type: String,
      default: ''
    },
    editRoute: {
      type: String,
      default: '',
    }
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Datos de sistema'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectDatoSistema(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idsistema
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectDatoSistemaRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'iddato_sistema')
      } finally {
        this.loadingData = false
      }
    },
    clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: this.addRoute,
      })
    },
    clickListItem (item) {
      if (this.hasEditPerm(this.$route.meta.permission.idobjeto)) {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: this.editRoute,
          params: {
            iddato_sistema: item.data.iddato_sistema,
          },
        })
      }
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar el dato "${data.item.tdato_sistema_desc}"?`
      )
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await Data.deleteDatoSistema(this, data.item.iddato_sistema)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
  },
}
</script>

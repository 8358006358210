import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectDatoSistema (Vue, filter, search, sorter, page, idsistema) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idsistema', idsistema)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('tdato_sistema_desc', search)
        .addILike('valor', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    const resp = await Vue.$api.call(
      'datoSistema.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectDatoSistemaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('iddato_sistema', pks)
    const resp = await Vue.$api.call('datoSistema.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteDatoSistema (Vue, iddatoSistema) {
    await Vue.$api.call('datoSistema.delete', { iddato_sistema: iddatoSistema })
  },
}
